import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useUserContext } from '../../context/userContext';
import { S3_URL } from '../../utils/BaseUrl';
import Logo from './Logo';

export default function MainNavbar() {
    const { userDetails } = useUserContext();
    const location = useLocation()
    const path = location.pathname
    return (
        <>
            {/* <!-- navbar --> */}
            <div className="dash-nav">
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                    <div className="container">
                        <Link to="/dashboard" className="">
                            {/* <img style={{ width: "8%" }} src="/logo.png" alt="" /> */}
                            <Logo width="100px" logoUrl='/Group.png' />
                            </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                {/* {userDetails?.roleId?.permission.includes("poRead") && <li className="nav-item">
                                    <Link to="/purchase" className={`nav-link ${path.includes("purchase") ? "active" : ""}`} aria-current="page">Purchase Order</Link>
                                </li>}
                                {userDetails?.roleId?.permission.includes("challanRead") && <li className="nav-item">
                                    <Link to="/challan" className={`nav-link ${path.includes("challan") ? "active" : ""}`}>Challan</Link>
                                </li>}
                                {userDetails?.roleId?.permission.includes("voucherRead") && <li className="nav-item">
                                    <Link to="/voucher" className={`nav-link ${path.includes("voucher") ? "active" : ""}`}>Voucher</Link>
                                </li>}
                                <li className="nav-item">
                                    <a className="nav-link" href="#">My Cashbook</a>
                                </li>*/}

                                <li className="nav-item ms-2">
                                    <div>
                                        <div><span className=''><img src={userDetails?.siteId?.companyId?.companyLogo ?  S3_URL + userDetails?.siteId?.companyId?.companyLogo: "/noLogoUploaded.png"} alt="" style={{ width: "50px", height: "50px" }} className='img-fluid rounded'/></span></div>
                                    </div>
                                </li>
                                <li className="nav-item ms-2">
                                    {/* <div>
                                        <div><span className='text-white fw-bold'>Site:</span> <span className='text-white'>{userDetails?.siteId?.name}</span></div>
                                        <div><span className='text-white fw-bold'>Company:</span> <span className='text-white'>{userDetails?.siteId?.companyId?.name}</span></div>
                                    </div> */}
                                </li>
                                
                            </ul>
                        </div>
                        {/* <form className="d-flex" role="search">
                            <input className="form-control" type="search" placeholder="Search for" aria-label="Search" />
                        </form> */}


                        
                        <div className="dropdown ms-2 ">
                            <div className="btn bg-white " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className='d-flex justify-content-center align-items-center'>  <img src={userDetails?.profileImage ? S3_URL + userDetails?.profileImage : "/images/person-circle.svg"} alt='profile' style={{ width: "25px", height: "25px" }} className='rounded-circle img-fluid me-1' />
                                    <span>{`${userDetails.firstName ? userDetails.firstName : ""} ${userDetails.lastName ? userDetails.lastName : ""}`}</span>
                                </div>
                                <div className="float-start badge rounded-pill bg-light text-dark">{userDetails?.designation}</div>
                            </div>

                            <ul className="dropdown-menu">
                                {/* <li>
                                    <Link to="/manage-sites" className="dropdown-item" >
                                        Manage Sites
                                    </Link>
                                </li>
                                <li>
                                    <Link target='_blank' to="/termsandconditions" className="dropdown-item" >
                                       Terms and Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link target='_blank' to="/privacypolicy" className="dropdown-item" >
                                       Privacy Policy
                                    </Link>
                                </li> */}
                                <li>
                                    <Link data-bs-toggle="modal" className="dropdown-item" data-bs-target="#exampleModalout1" >
                                        Log Out
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}
