import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Login from "../pages/login/Login"
import Dashboard from '../pages/dashbaord/Dashboard';
import Otpverification from '../pages/login/Otpverification';
import Sidebar from '../components/Layout/Sidebar';
import Header from '../components/common/Header';
import AddPurchaseOrder from '../pages/purchageOrder/AddPurchaseOrder';
import Challan from '../pages/challan/Challan';
import ViewPurchaseOrder from '../pages/purchageOrder/ViewPurchaseOrder';
import AddChallan from '../pages/challan/AddChallan';
import ViewChallan from '../pages/challan/ViewChallan';
import Home from '../pages/home';
import DashboardTemplate from '../components/common/dashboardTemplate';
import Upload from '../pages/upload/Upload';
import AddOrUpdateUpload from '../pages/upload/AddOrUpdateUpload';
import PurchaseTable from '../pages/purchageOrder/PurchaseTable';
import Voucher from '../pages/voucher/Voucher';
import AddOrUpdateVoucher from '../pages/voucher/AddOrUpdateVoucher';
import ViewVoucher from '../pages/voucher/ViewVoucher';
import ManageSites from '../pages/user/ManageSites';
import Cashbook from '../pages/cashbook/cashbook';
import Reports from '../pages/report/ReportMain';
import { useUserContext } from '../context/userContext';
import TermsAndCondition from '../pages/termsAndCondition';
import Privacy from '../pages/privacy';
import Member from '../pages/team/member';
import AllProject from '../pages/project/allProject';
import ShowFolder from '../pages/project/showFolder';
import ResetPassword from '../pages/login/ResetPassword';
import ShowFile from '../pages/project/showfile';

function PrivateRoute({ children }) {
  const auth = localStorage.getItem("token");
  return auth ? <DashboardTemplate>{children}</DashboardTemplate>  : <Navigate to="/" />;
}

function PublicRoute({ children }) {
  const auth = localStorage.getItem("token");
  return !auth ? children : <Navigate to="/dashboard" />;
}

export default function Mainroute() {
  const { userDetails } = useUserContext();
  return (
    <>

      <BrowserRouter>
        {/* <Sidebar /> */}
        {/* <Header /> */}


        <Routes>
          <Route exact path="/signin" element={<PublicRoute><Login /></PublicRoute>} />
          <Route exact path="/resetPassword/:token" element={<PublicRoute><ResetPassword /></PublicRoute>} />
          <Route exact path="/otp-verification" element={<PublicRoute><Otpverification /></PublicRoute>} />
          <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route exact path="/team" element={<PrivateRoute><Member /></PrivateRoute>} />
          <Route exact path="/project" element={<PrivateRoute><AllProject /></PrivateRoute>} />
          <Route exact path="/project/:projectId?" element={<PrivateRoute><ShowFolder /></PrivateRoute>} />
          <Route exact path="/project/file/:fileId" element={<PrivateRoute><ShowFile /></PrivateRoute>} />


          <Route exact path="/purchase" element={<PrivateRoute><PurchaseTable /></PrivateRoute>} />
          <Route exact path="/purchase/add" element={<PrivateRoute><AddPurchaseOrder /></PrivateRoute>} />
          <Route exact path="/purchase/update/:purchaseOrderId" element={<PrivateRoute><AddPurchaseOrder /></PrivateRoute>} />
          <Route exact path="/purchase/view/:id" element={<PrivateRoute><ViewPurchaseOrder /></PrivateRoute>} />
          <Route exact path="/challan" element={<PrivateRoute><Challan /></PrivateRoute>} />
          <Route exact path="/challan/view/:id" element={<PrivateRoute><ViewChallan /></PrivateRoute>} />
          <Route exact path="/challan/Edit/:id" element={<PrivateRoute><AddChallan /></PrivateRoute>} />
          <Route exact path="/upload" element={<PrivateRoute><Upload /></PrivateRoute>} />
          <Route exact path="/upload/add" element={<PrivateRoute><AddOrUpdateUpload /></PrivateRoute>} />
          <Route exact path="/voucher" element={<PrivateRoute><Voucher /></PrivateRoute>} />
          <Route exact path="/voucher/add/:id?" element={<PrivateRoute><AddOrUpdateVoucher /></PrivateRoute>} />
          <Route exact path="/voucher/view/:id" element={<PrivateRoute><ViewVoucher /></PrivateRoute>} />
          <Route  exact path="/manage-sites" element={<PrivateRoute><ManageSites /></PrivateRoute>} />
          {userDetails?.roleId?.permission.includes("cashbookRead") && <Route exact path="/cashbook" element={<PrivateRoute><Cashbook /></PrivateRoute>} />}
          {userDetails?.roleId?.permission.includes("reportRead") && <Route exact path="/report" element={<PrivateRoute><Reports /></PrivateRoute>} />}
          <Route path="/*" element={<PublicRoute><Login /></PublicRoute>} />
          <Route exact path="/" element={<PublicRoute><Home /></PublicRoute>} />
          <Route exact path="/termsandconditions" element={<TermsAndCondition />} />
          <Route exact path="/privacypolicy" element={<Privacy />} />

        </Routes>

      </BrowserRouter>
      <div id="recaptcha-container" ></div>
    </>
  )

}
