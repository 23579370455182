import React, { useEffect, useState } from 'react'
import { addMemberApi, addProjectApi, addUsersToProjectApi, getAllProjectApi, getTeamMemberApi } from '../../utils/ApiEndpointFunctions';
import { useNavigate, useSearchParams, useLocation, Link } from 'react-router-dom';
import Spinwheel from '../../components/common/Spinwheel';
import { useUserContext } from '../../context/userContext';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import ReactPaginate from 'react-paginate';
import AddMemberModal from '../../components/modal/addMember';
import { addMemberInitialValue, addMemberValidationSchema, addProjectInitialValue, addProjectMemberInitialValue, addProjectMemberValidationSchema, addProjectValidationSchema } from '../../utils/Validation';
import AddProjectModal from '../../components/modal/addProject';
import AddProjectMemberModalId from '../../components/modal/addProjectMember';

export default function AllProject(props) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState({ po: true })
  const navigate = useNavigate();
  const { userDetails } = useUserContext();
  const addProjectModalId = "addProjectModalId"
  const addProjectMemberModalId = "addProjectMemberModalId"
  const statusModalId = "statusModal"
  const limit = 10;
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedProject,setSelectedProject] = useState(null)
  const location = useLocation()

  const [totalCount, setTotalCount] = useState(null);
  const [addProject,setAddProject] = useState(false)
  const [addProjectMember,setAddProjectMember] = useState(false)


  const getMemberList = async () => {
    setLoading({ ...loading, po: true });
    try {
      const page = searchParams.get("page") || 1
      const res = await getAllProjectApi(limit, page);
      if (res.status === 200) {
        setData(res.data?.data?.data);
        setTotalCount(res.data?.data?.totalCount);
        setPage(Math.ceil(res.data?.data?.totalCount / limit));
      }
    } catch (error) {
      console.log(error)
    }
    setLoading({ ...loading, po: false });
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;

    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", currentPage);

    if (newParams.has("tab")) {
      newParams.set("tab", newParams.get("tab"));
    }

    setSearchParams(newParams);
  };


  const handleSubmit = async (values) => {
   try {
     setAddProject(true);
     let members = values?.members?.map(mem => mem?.value?._id)
     const response = await addProjectApi({...values,members})
     if (response.status === 200) {
      document.getElementById(addProjectModalId).click()
      getMemberList();
       toast.success(response.data?.message);
       addProjectFormik?.resetForm()
     }
   } catch (error) {
     console.log(error)
     error && error.response ?
       toast.error(error.response?.data?.message) :
       toast.error(error?.message);
   }
      setAddProject(false);
 }

  const addProjectFormik = useFormik({
    initialValues: addProjectInitialValue,
    onSubmit: handleSubmit,
    validationSchema: addProjectValidationSchema
  })

  const handleProjectMemberSubmit =async(values)=>{
   try {
      let userIds = values?.members?.map(mem => mem?.value?._id)
      setAddProjectMember(true);
      const response = await addUsersToProjectApi({userIds,projectId:selectedProject?.id})
      if (response.status === 200) {
       document.getElementById(addProjectMemberModalId).click()
       getMemberList();
        toast.success(response.data?.message);
        addProjectMemberFormik?.resetForm()
      }
    } catch (error) {
      console.log(error)
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error(error?.message);
    }
       setAddProjectMember(false);
  }

  const addProjectMemberFormik = useFormik({
   initialValues: addProjectMemberInitialValue,
   onSubmit: handleProjectMemberSubmit,
   validationSchema: addProjectMemberValidationSchema
 })

 

  // useEffect(() => {
  //   getMemberList()
  // }, [])

  useEffect(() => {
      getMemberList()
  },[searchParams.get('page')])

  return (
    <>

      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">

              {props.type !== "dashboard" && <div className="dash-nav1">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-blue">All Project</h3>
                  {userDetails?.roleId?.permission?.includes("addProject") && <button className="btn  create-order mb-3" type="button" data-bs-toggle="modal" data-bs-target={`#${addProjectModalId}`}>Add</button>}
                </div>
              </div>}
              {/* <!-- table --> */}
              <div className="table-responsive" style={{ overflowX: "inherit" }}>
                <table className="table d-table mt-4">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Company name</th>
                      <th scope="col">Client name</th>
                      <th scope="col">Client email</th>
                      <th scope="col">Project url</th>
                      <th scope="col">CREATED DATE</th>
                      <th scope="col">STATUS</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  {
                    loading.po ?
                      <tbody >
                        <tr>
                          <td colSpan={12}>
                            <div className="d-flex justify-content-center">
                              <Spinwheel />
                            </div>
                          </td>
                        </tr>
                      </tbody> :
                     
                        <tbody>
                          {data?.length > 0 ? data?.map((ele, index) => {
                            return (
                              <tr key={index}>
                                <td><Link to={`/project/${ele?.project?._id}`}>{ele?.project?.name}</Link></td>
                                <td>{ele?.project?.companyName}</td>
                                <td>{ele?.project?.clientName}</td>
                                <td>{ele?.project?.clientEmail}</td>
                                <td><Link to={ele?.project?.projectUrl} target='_blank'>View</Link></td>
                                <td>{moment(ele?.project?.createdAt).format("DD/MM/YYYY")}</td>
                                <td><button className={` cursor-text text-capitalize ${ele?.project?.isActive ? "approve" :  "reject"} `}>{ele?.project?.isActive ? "Active" : "un-active"}</button></td>
                                <td>
                                  <div className="d-flex justify-content-start align-items-center">
                                    <div>
                                      <div className="dropdown">
                                        <button className="dots dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                        <ul className="dropdown-menu">
                                         
                                           <li className='dropdown-item'  onClick={() =>navigate(`/project/${ele?.project?._id}`) } >View</li>
                                          {userDetails?.roleId?.permission.includes("addProjectMember") &&
                                            <li className='dropdown-item' data-bs-toggle="modal" data-bs-target={`#${addProjectMemberModalId}`} onClick={() => { setSelectedProject({id:ele?.project?._id});addProjectMemberFormik?.resetForm() }} >Add Member</li>}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>)
                          }) : <tr className='text-center'>
                            <td colSpan="12">
                              <h1>Data Not Found</h1>
                            </td>
                          </tr>}
                        </tbody>}
                </table>
              </div>
              {totalCount > limit ? (
                <div className="d-flex justify-content-center">
                  <ReactPaginate
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    pageCount={page}
                    previousLabel="< Previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName={"active"}
                    forcePage={searchParams.get('page')? Number(searchParams.get('page'))-1 : 0}
                  />
                </div>
              ) : (
                ""
              )}
              <AddProjectModal modalId={addProjectModalId} formik={addProjectFormik} addProject={addProject}/>
              <AddProjectMemberModalId modalId={addProjectMemberModalId} formik={addProjectMemberFormik} addProjectMember={addProjectMember}/>
              <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`}/>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
