import { useFormik } from 'formik';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { SignInInitaialValue, SignInValidationSchema } from '../../utils/Validation';
import { auth } from "../../utils/firebase";
import PhoneInput from "react-phone-input-2";
import Spinwheel from '../../components/common/Spinwheel';
import { getProfileApi, isExistApi, signInApi } from '../../utils/ApiEndpointFunctions';
import Logo from '../../components/common/Logo';
import { useUserContext } from '../../context/userContext';
import { removeToken } from '../../utils/HelperFuncitons';
function Login() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setUserDetails } = useUserContext();


  const getUserDetails = async () => {
    const { data } = await getProfileApi();
    console.log(data);
    
    if (data && data.data) {
      setUserDetails(data.data)
    } else {
      removeToken();
    }
  }


  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await signInApi(values)
      if (response.status === 200) {
        localStorage.setItem("token", response.data?.token);
        navigate("/dashboard");
        toast.success(response.data?.message);
        getUserDetails();
      }
    } catch (error) {
      console.log(error)
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error(error?.message);
      setLoading(false);
    }
  }


  const formik = useFormik({
    initialValues: SignInInitaialValue,
    onSubmit: handleSubmit,
    validationSchema: SignInValidationSchema
  })

  return (
    <section className="login">
      <div className="container">
        <div className="centralize">
          <div className="login-box">
            <Logo width="100%" logoUrl="/Group.png" />
            <form className="mt-4 " onSubmit={formik.handleSubmit}>
              <label htmlFor="email">Email</label>
              <input className="w-100 mb-3" type="email" name='email' value={formik.values?.email} onChange={formik.handleChange} placeholder="Enter registered email" />
              {formik.touched.email && formik.errors.email ? (<div className='text-danger'>{formik.errors.email}</div>) : null}
              <label htmlFor="password">Password</label>
              <input className="w-100 mb-3" type="password" name='password' value={formik.values?.password} onChange={formik.handleChange} placeholder="Password" />
              {formik.touched.password && formik.errors.password ? (<div className='text-danger'>{formik.errors.password}</div>) : null}
              <button type='submit' disabled={loading} className="login-btn text-center mt-4">
                {loading ? <Spinwheel sizeclassName="spin-small" /> : "Verify"}
              </button>
            </form>
            <p className="mt-4">If your email is not registered yet with your company account, please contact your administrator team.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login