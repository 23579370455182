import React, { useEffect, useState } from 'react'
import Spinwheel from '../common/Spinwheel';


function AddFolderModal(props) {
  const { modalId, formik,addFolder } = props

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>Add Folder</h5>
                </div>
                <form className="my-fx">

                  <div className="ed-box">
                    <label htmlFor="name" >Name</label>
                    <input type="text" className="form-control" id="name" placeholder="Enter name" name='name' value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.name && formik.errors.name ? (<div className='text-danger'>{formik.errors.name}</div>) : null}
                  </div>

                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <button disabled={addFolder} className="save" onClick={formik.handleSubmit}>
                    {addFolder ? <Spinwheel sizeclassName="spin-small" /> : "Add"}
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddFolderModal