import React, { useEffect, useState } from 'react'
import { addMemberApi, getTeamMemberApi } from '../../utils/ApiEndpointFunctions';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Spinwheel from '../../components/common/Spinwheel';
import { useUserContext } from '../../context/userContext';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import ReactPaginate from 'react-paginate';
import AddMemberModal from '../../components/modal/addMember';
import { addMemberInitialValue, addMemberValidationSchema } from '../../utils/Validation';

export default function Member(props) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState({ po: true })
  const navigate = useNavigate();
  const { userDetails } = useUserContext();
  const addMemberModalId = "addMemberModalId"
  const statusModalId = "statusModal"
  const limit = 10;
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation()

  const [totalCount, setTotalCount] = useState(null);
  const [addTeam,setAddTeam] = useState(false)

  const getMemberList = async () => {
    setLoading({ ...loading, po: true });
    try {
      const page = searchParams.get("page") || 1
      const res = await getTeamMemberApi(limit, page);
      if (res.status === 200) {
        setData(res.data?.data);
        setTotalCount(res.data?.totalCount);
        setPage(Math.ceil(res.data?.totalCount / limit));
      }
    } catch (error) {
      console.log(error)
    }
    setLoading({ ...loading, po: false });
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;

    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", currentPage);

    if (newParams.has("tab")) {
      newParams.set("tab", newParams.get("tab"));
    }

    setSearchParams(newParams);
  };


  const handleSubmit = async (values) => {
   try {
     setAddTeam(true);
     const response = await addMemberApi(values)
     if (response.status === 200) {
      document.getElementById(addMemberModalId).click()
      getMemberList();
       toast.success(response.data?.message);
       addMemberFormik?.resetForm()
     }
   } catch (error) {
     console.log(error)
     error && error.response ?
       toast.error(error.response?.data?.message) :
       toast.error(error?.message);
   }
      setAddTeam(false);
 }

  const addMemberFormik = useFormik({
    initialValues: addMemberInitialValue,
    onSubmit: handleSubmit,
    validationSchema: addMemberValidationSchema
  })

  // useEffect(() => {
  //   getMemberList()
  // }, [])

  useEffect(() => {
      getMemberList()
  },[searchParams.get('page')])

  return (
    <>

      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">

              {props.type !== "dashboard" && <div className="dash-nav1">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-blue">Team</h3>
                  {userDetails?.roleId?.permission?.includes("addTeam") && <button className="btn  create-order mb-3" type="button" data-bs-toggle="modal" data-bs-target={`#${addMemberModalId}`}>Add</button>}
                </div>
              </div>}
              {/* <!-- table --> */}
              <div className="table-responsive" style={{ overflowX: "inherit" }}>
                <table className="table d-table mt-4">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Role</th>
                      <th scope="col">CREATED DATE</th>
                      <th scope="col">STATUS</th>
                      {/* <th scope="col">ACTION</th> */}
                    </tr>
                  </thead>
                  {
                    loading.po ?
                      <tbody >
                        <tr>
                          <td colSpan={12}>
                            <div className="d-flex justify-content-center">
                              <Spinwheel />
                            </div>
                          </td>
                        </tr>
                      </tbody> :
                     
                        <tbody>
                          {data?.length > 0 ? data?.map((ele, index) => {
                            return (
                              <tr key={index}>
                                <td>{ele?.firstName} {ele?.lastName}</td>
                                <td>{ele?.email}</td>
                                <td>{ele?.designation}</td>
                                <td>{ele?.roleId?.name}</td>
                                <td>{moment(ele?.createdAt).format("DD/MM/YYYY")}</td>
                                <td><button className={` cursor-text text-capitalize ${ele?.isActive ? "approve" :  "reject"} `}>{ele?.isActive ? "Active" : "un-active"}</button></td>
                                {/* <td>
                                  <div className="d-flex justify-content-start align-items-center">
                                    <div>
                                      {userDetails?.roleId?.permission.includes("poReject") && ele?.status?.toLowerCase() !== "reject" && <button className="reject">Reject</button>}
                                    </div>
                                    <div>
                                      <div className="dropdown">
                                        <button className="dots dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                        <ul className="dropdown-menu">
                                          {userDetails?.roleId?.permission.includes("poRead") && <li className='dropdown-item' onClick={() => { navigate(`/purchase/view/${ele?._id}`) }} >View</li>}
                                          {(((userDetails?.roleId?.permission.includes("poUpdate")) && ele?.status?.toLowerCase() != "approved") || ele?.status?.toLowerCase() == "created") && <li className='dropdown-item' onClick={() => { navigate(`/purchase/update/${ele?._id}`) }} >Edit</li>}
                                          {userDetails?.roleId?.permission.includes("poApprove") && ele?.status?.toLowerCase() != "approved" &&
                                            <li className='dropdown-item' data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={() => { setSelectPO({id:ele?._id,poNo:ele?.poNumber}) }} >Approve</li>}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </td> */}
                              </tr>)
                          }) : <tr className='text-center'>
                            <td colSpan="12">
                              <h1>Data Not Found</h1>
                            </td>
                          </tr>}
                        </tbody>}
                </table>
              </div>
              {totalCount > limit ? (
                <div className="d-flex justify-content-center">
                  <ReactPaginate
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    pageCount={page}
                    previousLabel="< Previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName={"active"}
                    forcePage={searchParams.get('page')? Number(searchParams.get('page'))-1 : 0}
                  />
                </div>
              ) : (
                ""
              )}
              <AddMemberModal modalId={addMemberModalId} formik={addMemberFormik} addTeam={addTeam}/>
              <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`}/>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
