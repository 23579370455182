import React, { useEffect, useState } from 'react'
import Spinwheel from '../common/Spinwheel';
import { getTeamMemberApi } from '../../utils/ApiEndpointFunctions';
import AsyncSelect from 'react-select/async';


function AddProjectModal(props) {
  const { modalId, formik,addProject } = props


  const getMemberList = async () => {
    try {
      const res = await getTeamMemberApi();
      if (res.status === 200) {
        let options = res.data?.data?.map(ele=>{ return {label:`${ele?.firstName || ''} ${ele?.lastName || ''}`,value:ele}})
        return options
      }
    } catch (error) {
       console.log(error)
       return []
    }
  }

  const handleSelect =(e)=>{
    formik.setFieldValue("members",e)
    
  }

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>Add Project</h5>
                </div>
                <form className="my-fx">

                  <div className="ed-box">
                    <label htmlFor="name" >Name</label>
                    <input type="text" className="form-control" id="name" placeholder="Enter name" name='name' value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.name && formik.errors.name ? (<div className='text-danger'>{formik.errors.name}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="companyName" >Company name</label>
                    <input type="text" className="form-control" id="companyName" placeholder="Enter company name" name='companyName' value={formik?.values?.companyName} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.companyName && formik.errors.companyName ? (<div className='text-danger'>{formik.errors.companyName}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="projectUrl" >Project URL</label>
                    <input type="text" className="form-control" id="projectUrl" placeholder="Enter project url" name='projectUrl' value={formik?.values?.projectUrl} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.projectUrl && formik.errors.projectUrl ? (<div className='text-danger'>{formik.errors.projectUrl}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="clientName" >Client name</label>
                    <input type="text" className="form-control" id="clientName" placeholder="Enter clientName" name='clientName' value={formik?.values?.clientName} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.clientName && formik.errors.clientName ? (<div className='text-danger'>{formik.errors.clientName}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="clientEmail" >Client email</label>
                    <input type="text" className="form-control" id="clientEmail" placeholder="Enter clientEmail" name='clientEmail' value={formik?.values?.clientEmail} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.clientEmail && formik.errors.clientEmail ? (<div className='text-danger'>{formik.errors.clientEmail}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="name" >Select member</label>
                    <AsyncSelect isMulti cacheOptions defaultOptions onChange={handleSelect} loadOptions={getMemberList} />
                    {formik.touched.name && formik.errors.name ? (<div className='text-danger'>{formik.errors.name}</div>) : null}
                  </div>
                  <div className="ed-box d-flex gap-2">
                    <label htmlFor="enableClient" >Enable client</label>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" name='enableClient'  role="switch" id="flexSwitchCheckChecked" checked={formik?.values?.enableClient} onClick={formik?.handleChange} onBlur={formik?.handleBlur}/>
                     </div>
                    {formik.touched.clientEmail && formik.errors.clientEmail ? (<div className='text-danger'>{formik.errors.clientEmail}</div>) : null}
                 
                  </div>
                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <button disabled={addProject} className="save" onClick={formik.handleSubmit}>
                    {addProject ? <Spinwheel sizeclassName="spin-small" /> : "Add"}
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddProjectModal