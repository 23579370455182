import React, { useEffect, useState } from 'react'
import Spinwheel from '../common/Spinwheel';
import { convertByteToMB } from '../../utils/HelperFuncitons';
import { FILE_SIZE_ERROR, MAX_FILE_SIZE } from '../../utils/constant';
import toast from 'react-hot-toast';
import { fileUploadApi } from '../../utils/ApiEndpointFunctions';


function AddFileModal(props) {
  const { modalId, formik,addFile } = props


  const fileUploadHandler = async (e,pathName) => {
   try {
 
     const file1 = e.target.files[0];
 
     const file = new File([file1], `${new Date().getTime()}_${file1.name}`, {
       type: file1.type,
       lastModified: file1.lastModified,
     });
 
 
     if (file) {
 
      //  if (!FILE_ACCEPT.includes(file.type)) {
      //    toast.error(INVALID_FILE_FORMAT_ERROR_MESSAGE)
      //    return;
      //  }
 
       const fileSizeInMB = convertByteToMB(file.size || 0);
 
       if (fileSizeInMB > MAX_FILE_SIZE) {
         return toast.error(FILE_SIZE_ERROR)
       }
 
       const formData = new FormData();
       formData.append("file", file);
       formData.append("type", "file")
 
       const res = await fileUploadApi(formData)
       if (res.status === 200 && res.data?.status === 1) {
         toast.success("File uploaded");
         formik.setFieldValue(pathName, res.data?.data)
 
       }
     }
 
   } catch (error) {
     console.log(error)
     if (error?.response === undefined) {
       toast.error("There was an error uploading your file. Please try again later.")
     }
   }
 
 }

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>Add File</h5>
                </div>
                <form className="my-fx">
                <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} >
                      <label htmlFor="filePath" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                        <input type="file" className='btn btn_upload_file d-none ' name="filePath" id='filePath' onChange={(e) => { fileUploadHandler(e, "filePath") }}  />
                        {!formik.values?.filePath ? <span className="bi bi-paperclip m-auto"></span> : 
                       <i class="bi bi-file-earmark"></i>}
                      </label>
                      </div>
                      {formik.touched.filePath && formik.errors.filePath ? (<div className='text-danger'>{formik.errors.filePath}</div>) : null}

                  <div className="ed-box">
                    <label htmlFor="name" >Name</label>
                    <input type="text" className="form-control" id="name" placeholder="Enter name" name='name' value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.name && formik.errors.name ? (<div className='text-danger'>{formik.errors.name}</div>) : null}
                  </div>

                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <button disabled={addFile} className="save" onClick={formik.handleSubmit}>
                    {addFile ? <Spinwheel sizeclassName="spin-small" /> : "Add"}
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddFileModal