import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { fileUploadApi, getAllRoleApi } from '../../utils/ApiEndpointFunctions';
import Spinwheel from '../common/Spinwheel';
import { FILE_ACCEPT, FILE_SIZE_ERROR, INVALID_FILE_FORMAT_ERROR_MESSAGE, MAX_FILE_SIZE } from '../../utils/constant';
import { convertByteToMB } from '../../utils/HelperFuncitons';
import toast from 'react-hot-toast';
import { S3_URL } from '../../utils/BaseUrl';


function AddMemberModal(props) {
  const { modalId, formik,addTeam } = props
  const [roles, setRoles] = useState()
  const getRoles = async () => {
    try {
      const res = await getAllRoleApi()
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.map(ele => ({ label: ele.name, value: ele?._id }))
        setRoles(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getRoles()
  }, [])

  const fileUploadHandler = async (e,pathName) => {
    try {
  
      const file1 = e.target.files[0];
  
      const file = new File([file1], `${new Date().getTime()}_${file1.name}`, {
        type: file1.type,
        lastModified: file1.lastModified,
      });
  
  
      if (file) {
  
        if (!FILE_ACCEPT.includes(file.type)) {
          toast.error(INVALID_FILE_FORMAT_ERROR_MESSAGE)
          return;
        }
  
        const fileSizeInMB = convertByteToMB(file.size || 0);
  
        if (fileSizeInMB > MAX_FILE_SIZE) {
          return toast.error(FILE_SIZE_ERROR)
        }
  
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "profile")
  
        const res = await fileUploadApi(formData)
        if (res.status === 200 && res.data?.status === 1) {
          toast.success("File uploaded");
          formik.setFieldValue(pathName, res.data?.data)
  
        }
      }
  
    } catch (error) {
      console.log(error)
      if (error?.response === undefined) {
        toast.error("There was an error uploading your file. Please try again later.")
      }
    }
  
  }


  
  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>Add Team</h5>
                </div>
                <form className="my-fx">
                        Profile
                <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} >
                      <label htmlFor="profilePic" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                        <input type="file" className='btn btn_upload_file d-none ' name="profilePic" id='profilePic' onChange={(e) => { fileUploadHandler(e, "profilePic") }} accept={FILE_ACCEPT} />
                        {!formik.values?.profilePic ? <span className="bi bi-paperclip m-auto"></span> :
                        <img src={S3_URL + encodeURIComponent(formik.values?.profilePic)} alt="" className='img-fluid' />}
                      </label>
                      </div>

                  <div className="ed-box">
                    <label htmlFor="firstName" >First Name</label>
                    <input type="text" className="form-control" id="firstName" placeholder="Enter firstName" name='firstName' value={formik?.values?.firstName} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.firstName && formik.errors.firstName ? (<div className='text-danger'>{formik.errors.firstName}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="lastName" >Last Name</label>
                    <input type="text" className="form-control" id="lastName" placeholder="Enter lastName" name='lastName' value={formik?.values?.lastName} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.lastName && formik.errors.lastName ? (<div className='text-danger'>{formik.errors.lastName}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="email" >Email</label>
                    <input type="text" className="form-control" id="email" placeholder="Enter email" name='email' value={formik?.values?.email} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.email && formik.errors.email ? (<div className='text-danger'>{formik.errors.email}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="phone" >Phone No.</label>
                    <input type="text" className="form-control" id="phone" placeholder="Enter phone" name='phone' value={formik?.values?.phone} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.phone && formik.errors.phone ? (<div className='text-danger'>{formik.errors.phone}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="whatsapp" >Whatsapp No.</label>
                    <input type="text" className="form-control" id="whatsapp" placeholder="Enter whatsapp" name='whatsapp' value={formik?.values?.whatsapp} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.whatsapp && formik.errors.whatsapp ? (<div className='text-danger'>{formik.errors.whatsapp}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="designation" >Designation</label>
                    <input type="text" className="form-control" id="designation" placeholder="Enter designation" name='designation' value={formik?.values?.designation} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik.touched.designation && formik.errors.designation ? (<div className='text-danger'>{formik.errors.designation}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="vendorName" className="form-label">Role</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={formik?.values?.roleId ? roles?.filter(ele=>ele?.value==formik?.values?.roleId)?.[0] || null  : null}
                      placeholder="Select "
                      isSearchable={true}
                      name="state"
                      options={roles}
                      onChange={(e) => {
                        formik.setFieldValue("roleId", e.value)
                      }}
                    />
                     {formik.touched.roleId && formik.errors.roleId ? (<div className='text-danger'>{formik.errors.roleId}</div>) : null}
                  </div>


                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <button disabled={addTeam} className="save" onClick={formik.handleSubmit}>
                    {addTeam ? <Spinwheel sizeclassName="spin-small" /> : "Add"}
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddMemberModal