import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { getAllRoleApi, getTeamMemberApi } from '../../utils/ApiEndpointFunctions';
import Spinwheel from '../common/Spinwheel';
import AsyncSelect from 'react-select/async';


function AddProjectMemberModalId(props) {
  const { modalId, formik,addProjectMember } = props


  const getMemberList = async () => {
   try {
     const res = await getTeamMemberApi();
     if (res.status === 200) {
       let options = res.data?.data?.map(ele=>{ return {label:`${ele?.firstName || ''} ${ele?.lastName || ''}`,value:ele}})
       return options
     }
   } catch (error) {
      console.log(error)
      return []
   }
 }

 const handleSelect =(e)=>{
   formik.setFieldValue("members",e)
   
 }

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">

              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>Add Project member</h5>
                </div>
                <form className="my-fx">

                  <div className="ed-box">
                    <label htmlFor="name" >Select member</label>
                    <AsyncSelect isMulti cacheOptions defaultOptions onChange={handleSelect} loadOptions={getMemberList} />
                    {formik.touched.name && formik.errors.name ? (<div className='text-danger'>{formik.errors.name}</div>) : null}
                  </div>
                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    <button type='submit' disabled={addProjectMember} className="save" onClick={formik.handleSubmit}>
                    {addProjectMember ? <Spinwheel sizeclassName="spin-small" /> : "Add"}
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddProjectMemberModalId