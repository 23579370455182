import React, { useEffect, useState } from 'react'
import { addFolderApi, addFolderFileApi, addMemberApi, addProjectApi, addUsersToProjectApi, getAllFolderApi, getAllProjectApi, getTeamMemberApi } from '../../utils/ApiEndpointFunctions';
import { useNavigate, useSearchParams, useLocation, Link } from 'react-router-dom';
import Spinwheel from '../../components/common/Spinwheel';
import { useUserContext } from '../../context/userContext';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import ReactPaginate from 'react-paginate';
import AddMemberModal from '../../components/modal/addMember';
import { addFileInitialValue, addFileValidationSchema, addFolderInitialValue, addFolderValidationSchema, addMemberInitialValue, addMemberValidationSchema, addProjectInitialValue, addProjectMemberInitialValue, addProjectMemberValidationSchema, addProjectValidationSchema } from '../../utils/Validation';
import AddProjectMemberModalId from '../../components/modal/addProjectMember';
import { useParams } from 'react-router-dom';
import AddFolderModal from '../../components/modal/addFolderModal';
import AddFileModal from '../../components/modal/addFile';


export default function ShowFolder(props) {
   const param = useParams()
  const [data, setData] = useState([])
  const [file, setFile] = useState([])
  const [loading, setLoading] = useState({ po: true })
  const navigate = useNavigate();
  const { userDetails } = useUserContext();
  const addProjectFolderModalId = "addProjectFolderModalId"
  const addFolderFileModalId = "addFolderFileModalId"
  const statusModalId = "statusModal"
  const limit = 10;
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedProject,setSelectedProject] = useState(null)
  const location = useLocation()

  const [totalCount, setTotalCount] = useState(null);
  const [addFolder,setAddFolder] = useState(false)
  const [addFile,setAddFile] = useState(false)
  const [addProjectMember,setAddProjectMember] = useState(false)


  const getShowFolderList = async () => {
    setLoading({ ...loading, po: true });
    try {
      const page = searchParams.get("page") || 1
      const folderId = searchParams.get("folderId") ||""
      const res = await getAllFolderApi(param?.projectId,folderId);
      if (res.status === 200) {
        setData(res.data?.data);
      }
    } catch (error) {
      console.log(error)
    }
    setLoading({ ...loading, po: false });
  }

  const handleSubmit = async (values) => {
   if(param?.projectId){
      try {
         setAddFolder(true);
         const nestedFolderId = searchParams?.get("folderId")
        const response = await addFolderApi({...values,projectId:param?.projectId,nestedFolderId})
        if (response.status === 200) {
         document.getElementById(addProjectFolderModalId).click()
         getShowFolderList();
          toast.success(response.data?.message);
          addProjectFolder?.resetForm()
        }
      } catch (error) {
        console.log(error)
        error && error.response ?
          toast.error(error.response?.data?.message) :
          toast.error(error?.message);
      }
   }
   setAddFolder(false);
 }

  const addProjectFolder = useFormik({
    initialValues: addFolderInitialValue,
    onSubmit: handleSubmit,
    validationSchema: addFolderValidationSchema
  })


  const handleFileSubmit =async(values)=>{
    if(searchParams?.get("folderId")){
      try {
         setAddFile(true);
         const folderId = searchParams?.get("folderId")
        const response = await addFolderFileApi({...values,folderId})
        if (response.status === 200) {
         document.getElementById(addFolderFileModalId).click()
         getShowFolderList();
          toast.success(response.data?.message);
          addFolderFile?.resetForm()
        }
      } catch (error) {
        console.log(error)
        error && error.response ?
          toast.error(error.response?.data?.message) :
          toast.error(error?.message);
      }
   }
   setAddFile(false);
  }


  const addFolderFile = useFormik({
    initialValues: addFileInitialValue,
    onSubmit: handleFileSubmit,
    validationSchema: addFileValidationSchema
  })



  useEffect(() => {
      getShowFolderList()
  },[searchParams.get('folderId')])

  console.log(data);


  const handleClick =(id,type)=>{
    console.log(id,type);
    
  if(type?.toLowerCase()=='file'){
     navigate(`/project/file/${id}`)
  }else{
      setSearchParams({folderId:id})
  }
  }


  

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">

              {props.type !== "dashboard" && <div className="dash-nav1">
                <div className="d-flex justify-content-between align-items-center">
                  <div className='d-flex gap-2 justify-content-between align-items-center'>
                  <i class="bi bi-arrow-left-circle cursor-pointer" onClick={()=>navigate(-1)}></i>
                  <h3 className="mb-0 font-blue">All Folder</h3>
                  </div>
                  <div className='d-flex gap-2'>
                  {userDetails?.roleId?.permission?.includes("addFolder") && <button className="btn  create-order mb-3" type="button" data-bs-toggle="modal" data-bs-target={`#${addProjectFolderModalId}`}>Add Folder</button>}
                  {userDetails?.roleId?.permission?.includes("addFile") && searchParams.get("folderId") && <button className="btn  create-order mb-3" type="button" data-bs-toggle="modal" data-bs-target={`#${addFolderFileModalId}`}>Add File</button>}
                  </div>
                </div>
              </div>}
              {/* <!-- table --> */}
              <div className="table-responsive" style={{ overflowX: "inherit" }}>
                <table className="table d-table mt-4">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Name</th>
                      <th scope="col">CREATED DATE</th>
                      <th scope="col">STATUS</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  {
                    loading.po ?
                      <tbody >
                        <tr>
                          <td colSpan={12}>
                            <div className="d-flex justify-content-center">
                              <Spinwheel />
                            </div>
                          </td>
                        </tr>
                      </tbody> :
                     
                        <tbody>
                          {data?.length > 0 ? data?.map((ele, index) => {
                            return (
                              <tr key={index}>
                                <td>{ele?.filePath ? <i class="bi bi-files"></i> : <i class="bi bi-folder2"></i>}</td>
                                <td onClick={()=>handleClick(ele?._id,ele?.filePath ? "File" : "Folder")} className='cursor-pointer link-primary text-decoration-underline'>{ele?.name}</td>
                                <td>{moment(ele?.createdAt).format("DD/MM/YYYY")}</td>
                                <td><button className={` cursor-text text-capitalize ${ele?.isActive ? "approve" :  "reject"} `}>{ele?.isActive ? "Active" : "un-active"}</button></td>
                                <td>
                                  <div className="d-flex justify-content-start align-items-center">
                                    <div>
                                      <div className="dropdown">
                                        <button className="dots dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                        <ul className="dropdown-menu">                                         
                                           <li className='dropdown-item'  onClick={()=>handleClick(ele?._id,ele?.filePath ? "File" : "Folder")} >View</li>                                         
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>)
                          }) : <tr className='text-center'>
                            <td colSpan="12">
                              <h1>Data Not Found</h1>
                            </td>
                          </tr>}
                        </tbody>}
                </table>
              </div>
              <AddFolderModal modalId={addProjectFolderModalId} formik={addProjectFolder} addFolder={addFolder}/>
              <AddFileModal modalId={addFolderFileModalId} formik={addFolderFile} addFolder={addFile}/>
              <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`}/>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
