import React, { useState, useRef, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addChatMessageApi,getFileDataApi } from '../../utils/ApiEndpointFunctions';
import { useUserContext } from '../../context/userContext';
import { S3_URL } from '../../utils/BaseUrl';
function ShowFile() {
   const param = useParams()
   const navigate = useNavigate();
   const { userDetails } = useUserContext();
  const [messages, setMessages] = useState([]);
  const [loading,setLoading] = useState(true)
  const [fileInfo,setFileInfo] = useState({})
  

  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);

  const handleSendMessage = async() => {
    if (newMessage.trim() !== '' && param?.fileId) {
      let message = newMessage
      setMessages([...messages, { message: newMessage,userId:userDetails?._id }]);
      setNewMessage('');
      try {
        const response = await addChatMessageApi({message,fileId:param?.fileId})
        if (response.status === 200) {
          toast.success(response.data?.message);
        }
      } catch (error) {
        console.log(error)
        error && error.response ?
          toast.error(error.response?.data?.message) :
          toast.error(error?.message);
      }
   }
  }

  const getFileData = async () => {
   setLoading({ ...loading, po: true });
   try {
     const res = await getFileDataApi(param?.fileId);
     if (res.status === 200) {
      let data = res?.data?.data?.[0]
      if(data){
         setMessages(data?.chat || [])
         setFileInfo(data)
      }
     }
   } catch (error) {
      console.log(error)
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error(error?.message);

   }
   setLoading({ ...loading, po: false });
 }


 
  useEffect(() => {
    // Scroll to the bottom whenever messages change
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(()=>{
   getFileData()
  },[])

  console.log(fileInfo);
  
  return (
<div className="container d-flex flex-column ">
  <div className='d-flex gap-2'>
  <i class="bi bi-arrow-left-circle cursor-pointer" onClick={()=>navigate(-1)}></i>
  Back
  </div>
  <div className="row my-3">
    <Link to={S3_URL + encodeURIComponent(fileInfo?.filePath)} target="_blank" className="d-flex gap-2 align-items-center border border-1 p-2 rounded-3 bg-white w-auto">
      <i className="bi bi-files fs-4"></i>
      {fileInfo?.name}
    </Link>
  </div>
  
  <div className="chat-box d-flex flex-column flex-grow-1 " style={{height: 500}}>
    {/* Chat messages container */}
    <div className="chat-msg flex-grow-1 overflow-auto my-3" >
      <div className="col">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`d-flex ${message.userId === userDetails?._id ? 'justify-content-end' : 'justify-content-start'} mb-2`}
          >
            <div className={`p-2 rounded ${message.userId === userDetails?._id ? 'bg-primary text-white' : 'bg-secondary text-light'}`}>
              {message.message}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
    
    {/* Message input area */}
    <div className="chat-send p-3 border-top">
      <div className="d-flex">
        <input
          type="text"
          className="form-control me-2"
          placeholder="Type a message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <button className="btn btn-primary" onClick={handleSendMessage}>
          Send
        </button>
      </div>
    </div>
  </div>
</div>

  );
}

export default ShowFile;
