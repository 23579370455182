import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../components/common/Logo'

export default function Home() {
  return (
   <section className="login-screen">
   <div className="container">
       <div className="centralize">
           <div className="login-box text-center">
               <h5>Welcome to</h5>
               {/* <div className="logo">
                   <img src="/Group.png" alt="logo"/>
               </div> */}
               <Logo width="280px" logoUrl="/Group.png" />
               {/* <p>For Small & Medium Construction Businesses & Contractors. Get Better Visibility Of Your Projects Regularly.</p> */}
               <div className='d-flex gap-2 align-items-center justify-content-center text-white mb-2'>
               {/* <Link target='_blank' to={"/termsandconditions"} className='text-white'>Terms and Conditions</Link>
               <Link target='_blank' to='/privacypolicy' className='text-white'>Privacy Policy</Link> */}
               </div>
               <Link to={"/signin"} className="login-btn">Log in </Link>
           </div>
       </div>
   </div>
</section>

  )
}
